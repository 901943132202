import React from 'react'
//import { kebabCase } from 'lodash'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Link from 'gatsby-link'
import Layout from '../../components/Layout'

import banner_1 from '../../images/banners/banner-1.jpg'

const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' }

const IndexBlogPage = ({
  location,
  data: {
    site: { siteMetadata: { title } },
    allPosts: { edges: posts },
    latestPosts: { edges: latestPosts },
  },
}) => (
    <Layout location={location}>
      <Helmet title={`Blog | ${title}`} />

      <div id="top-banner" className="container-fluid horizontal-section-container clearfix">
        <div className="row">
          <div className="col-xs-12">
            <img src={banner_1} alt="Blog" className="full-width-image" />
          </div>
        </div>
      </div>      

      <div id="breadcrumbs-container" className="container-fluid horizontal-section-container hidden-xs clearfix">
        <div className="row">
          <div className="col-xs-12">
            <ol className="breadcrumb">
              <li><Link to="/">Accueil</Link></li>
              {/* eslint-disable-next-line */}
              <li className="active"><a href="#">Blog</a></li>
            </ol>
          </div>
        </div>
      </div>

      <div className="container-fluid horizontal-section-container clearfix">
        <div className="row">

          <div className="col-sm-9">
            <div className="section-container main-page-content clearfix">

              <div className="section-content">

                <h1 className="page_title">Blog</h1>

                <div className="blog-articles-container clearfix">
                  {
                    posts
                      .map(({ node: post }) => (
                        <article className="blog-article clearfix" key={post.id}>
                          <div className="blog-article-content clearfix">
                            <h3 className="title">
                              <Link className="has-text-primary" to={post.fields.slug}>
                                {post.frontmatter.title}
                              </Link>
                            </h3>
                            <div className="meta clearfix">
                              <div className="meta-item posted-by"><span className="glyphicon glyphicon-user"></span> Par {post.frontmatter.author}</div>
                              <div className="meta-item date"><span className="glyphicon glyphicon-time"></span> {new Date(post.frontmatter.date).toLocaleDateString('fr-FR', dateOptions)}</div>
                            </div>
                            <div className="text-content excerpt clearfix">
                              <p>{post.excerpt}</p>
                              <Link className="btn btn-outline-inverse btn-md" to={post.fields.slug}>
                                lire la suite
                              </Link>
                            </div>
                          </div>
                        </article>
                      ))
                  }
                </div>

                {/* Pagination */}

              </div>
            </div>
          </div>

          <div className="col-sm-3">

            {/* Recent Posts */}
            <div id="recent-posts" className="section-container sm-extra-top-padding clearfix">

              <div className="section-header with-icon">
                <div className="icon"><span className="glyphicon glyphicon-th-list"></span></div>
                <h5 className="text">Derniers Billets</h5>
              </div>

              <div className="section-content clearfix">
                <ul className="vertical-simple-list">
                  {
                    latestPosts
                      .map(({ node: latestPost }) => (
                        <li className="item clearfix" key={latestPost.id}>
                          <div className="item-content">
                            <h6 className="title">
                              <Link to={latestPost.fields.slug}>{latestPost.frontmatter.title}</Link>
                            </h6>
                          </div>
                        </li>
                      ))
                  }

                </ul>
              </div>

            </div>

            {/* Tags */}
            {/* End: Tags */}

          </div>

        </div>
      </div>
    </Layout>
  )

export default IndexBlogPage

export const indexBlogPageQuery = graphql`
  query IndexBlogQuery {
    site {
      siteMetadata {
        title
      }
    },
    allPosts: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC },
      filter: { frontmatter: { templateKey: { eq: "blog-post" } }}
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            author
            title
            templateKey
            date
          }
        }
      }
    },
    latestPosts: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC },
      filter: { frontmatter: { templateKey: { eq: "blog-post" } }},
      limit: 5
    ) {
      edges {
        node {
          fields {
            slug
          }
          id
          frontmatter {
            title
          }
        }
      }
    }    
  }
`
